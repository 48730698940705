<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      :inline="true"
      label-position="top"
      class="flow-form">
      <header-title title="现经销商开票信息" />
      <RequiredFormItemInput
        class="formItem"
        prop="businessLicenseName"
        label="账户名称"
        v-model="form.businessLicenseName"
        delay-update
        maxlength="100"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        prop="creditCode"
        label="纳税人识别号"
        v-model="form.creditCode"
        delay-update
        maxlength="100"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        prop="businessLicenseAddress"
        label="营业执照地址"
        v-model="form.businessLicenseAddress"
        delay-update
        maxlength="100"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        prop="taxpayerPhone"
        label="纳税人电话"
        v-model="form.taxpayerPhone"
        :rules="rules.taxpayerPhone"
        delay-update
        maxlength="30"
        :disabled="isOA || disabled" />
      <RequiredBankFormItemSelect
        prop="bank"
        class="formItem"
        label="开户行"
        v-model="form.bank"
        :disabled="isOA || disabled"
        @changeSelectedItem="
          bankItem => {
            form.bankCode = bankItem?.CODE || ''
            form.bankCountryCode = bankItem?.AREACODE || ''
          }
        " />
      <RequiredFormItemInput
        v-model="form.bankCode"
        prop="bankCode"
        class="formItem"
        label="开户行编码"
        readonly
        delay-update />
      <RequiredFormItemInput
        class="formItem"
        prop="bankAccount"
        label="银行账号"
        v-model="form.bankAccount"
        :rules="rules.bankAccount"
        delay-update
        :disabled="isOA || disabled" />
      <RequiredFormItemSelect
        v-model="form.payTaxesType"
        class="formItem"
        prop="payTaxesType"
        label="纳税类型"
        :data-list="payTaxesType"
        label-key="content"
        value-key="code"
        disabled-key="disabled"
        :disabled="isOA || disabled" />
      <RequiredModuleFormItemSelect
        v-model="form.billType"
        class="formItem"
        prop="billType"
        label="发票类型"
        module="public"
        type="invoice_type"
        disabled-key="ifDelete"
        :disabled="isOA || disabled"
        :filter-data-list="dealerBusinessLicenseBillTypeFilterDataList"
        :get-data-list-params="{ ifDelete: '-1' }" />
      <RequiredFormItemInput
        class="formItem"
        prop="personLiableName"
        label="税务负责人"
        v-model="form.personLiableName"
        :disabled="isOA || disabled"
        delay-update
        maxlength="20" />
      <RequiredFormItemInput
        class="formItem"
        prop="personLiablePhone"
        label="税务负责人电话"
        v-model="form.personLiablePhone"
        :rules="rules.personLiablePhone"
        :disabled="isOA || disabled"
        delay-update
        maxlength="30" />
      <RequiredFormItemInput
        class="formItem"
        prop="eMail"
        label="接受电子发票邮箱"
        v-model="form.eMail"
        :rules="rules.eMail"
        :disabled="isOA"
        delay-update
        maxlength="100" />
      <div>
        <RequiredFormItemUploadFile
          style="width: 100%"
          prop="taxpayerProveFile"
          class="formItem"
          label="上传附件"
          limit-size="400"
          :limit="1"
          :on-preview="onPreview"
          v-model="form.taxpayerProveFile"
          :disabled="isOA"
          @onUploaded="
            () => {
              $refs['form'].validateField('taxpayerProveFile')
            }
          ">
          <div slot="describe">
            (1：一般纳税人登记证 或2：税务登记事项通知书或
            3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片）
          </div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
      </div>
      <div>
        <RequiredFormItemUploadFile
          style="width: 100%"
          prop="bankFile"
          class="formItem"
          label="上传附件"
          limit-size="400"
          :limit="1"
          :on-preview="onPreview"
          v-model="form.bankFile"
          :disabled="isOA"
          @onUploaded="
            () => {
              $refs['form'].validateField('bankFile')
            }
          ">
          <div slot="describe">开户证明或者网银截图(能显示开户银行全称）</div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
      </div>
    </el-form>
  </div>
</template>
<script>
import {
  RequiredFormItemInput,
  RequiredFormItemSelect,
  RequiredModuleFormItemSelect,
  RequiredFormItemUploadFile,
  RequestModuleSelect,
  RequiredBankFormItemSelect
} from '@/components/CustomFormItem'
import { mapState } from 'vuex'
import {
  validCreditCode,
  validEmail,
  validPhone,
  validbankAccount,
  filesRules
} from '@/utils/useFormValidator'
import HeaderTitle from './headerTitle.vue'
import moduleData from '@/components/CustomFormItem/data/module.data.js'
export default {
  props: {
    license: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    RequestModuleSelect,
    RequiredBankFormItemSelect,
    HeaderTitle,
    RequiredModuleFormItemSelect,
    RequiredFormItemSelect,
    RequiredFormItemInput,
    RequiredFormItemUploadFile
  },
  data() {
    return {
      businessLicenseType: '',
      form: {
        businessLicenseName: '', //开票名称===营业执照名称
        creditCode: '', //纳税人识别号===社会统一信用代码
        businessLicenseAddress: '', //纳税人地址===营业执照地址
        taxpayerPhone: '', //纳税人电话
        bank: '', //开户行,
        bankCode: '', //开户行编号
        bankCountryCode: '', //开户编码
        bankAccount: '', //开户账号
        bankAccountName: '', //开户行账户名称===营业执照名称
        payTaxesType: '', //纳税类型
        billType: '', //专票/普票
        personLiableName: '', //税务负责人
        personLiablePhone: '', //税务负责人电话
        eMail: '', //接受电子发票邮箱
        taxpayerProveFile: [], //开票资料
        bankFile: [] //开户资料
      },
      disabled: true,
      rules: {
        taxpayerPhone: [{ validator: validPhone, trigger: ['blur', 'change'] }], //纳税人电话
        bankAccount: [
          { validator: validbankAccount, trigger: ['blur', 'change'] }
        ], //开户账号
        personLiablePhone: [
          { validator: validPhone, trigger: ['blur', 'change'] }
        ], //税务负责人电话
        eMail: [{ validator: validEmail, trigger: ['blur', 'change'] }] //接受电子发票邮箱
      }
    }
  },
  watch: {
    historicalData: {
      handler(historicalData) {
        const dealerBusinessLicense =
          historicalData.bossJoinModel.dealerBusinessLicense
        for (const key in this.form) {
          if (key === 'taxpayerProveFile' || key === 'bankFile') {
            if (historicalData.bossJoinModel.taxpayerProveFile) {
              this.form['taxpayerProveFile'] = [
                historicalData.bossJoinModel.taxpayerProveFile
              ]
            }
            if (historicalData.bossJoinModel.bankFile) {
              this.form['bankFile'] = [historicalData.bossJoinModel.bankFile]
            }
          } else {
            this.form[key] = dealerBusinessLicense[key]
          }
        }
        this.disabled = true
      },
      deep: true
    },
    businessLicense: {
      handler(businessLicense) {
        console.log('更新', businessLicense)
        if (!businessLicense) {
          this.disabled = false
          for (const key in this.form) {
            if (key !== 'taxpayerProveFile' && key !== 'bankFile') {
              this.form[key] = ''
            }
          }
        } else {
          this.disabled = true
          for (const key in this.form) {
            if (key === 'eMail') {
              this.form[key] = businessLicense['email']
            } else {
              if (key !== 'taxpayerProveFile' && key !== 'bankFile') {
                this.form[key] = businessLicense[key]
              }
            }
          }
          if (JSON.stringify(this.historicalData) != '{}') {
            const eMail =
              this.historicalData['bossJoinModel']['dealerBusinessLicense'][
                'eMail'
              ]
            if (
              this.historicalData['newBusinessLicenseId'] ===
                businessLicense['id'] &&
              eMail
            ) {
              this.form['eMail'] = eMail
            }
          }
        }
      },
      deep: true
    },
    license: {
      handler(license) {
        if (!this.businessLicense) {
          this.form.businessLicenseName = license.businessLicenseName
          this.form.creditCode = license.creditCode
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState('dealershipTransfer', [
      'isOA',
      'businessLicense',
      'historicalData'
    ]),
    payTaxesType({ businessLicenseType }) {
      const payTaxesType = moduleData.pay_taxes_type
      console.log(payTaxesType)
      switch (businessLicenseType) {
        case '0':
          return payTaxesType.map(item => {
            return {
              ...item,
              disabled: item.code !== '1'
            }
          })
        case '1':
          return payTaxesType.map(item => {
            return {
              ...item,
              disabled: item.code === '1'
            }
          })
        default:
          return payTaxesType
      }
    }
  },
  methods: {
    dealerBusinessLicenseBillTypeFilterDataList(list) {
      if (list.length == 0) return []
      let tempList = list
      if (this.isOA) {
        return tempList
      }
      switch (String(this.form.payTaxesType)) {
        case '0':
          // 一般纳税人
          tempList = list.filter(item => {
            return ['1', '5'].includes(item.code)
          })
          break
        case '1':
        case '2':
          // 定额纳税人
          // 小规模纳税人
          tempList = list.filter(item => {
            return ['2', '6'].includes(item.code)
          })
          break
      }

      if (!tempList.some(item => item.code === this.form.billType)) {
        this.form.billType = ''
      }

      return tempList
    },
    onPreview(file) {
      window.open(file.url)
    }
  }
}
</script>
