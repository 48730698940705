<template>
  <FlowForm
    ref="flowFormRef"
    :model="form"
    :on-submit="onSubmit"
    :get-detail="getDetail"
    title="慕思经典经销商加盟流程申请表"
    :tips="[
      '1、现经销商第一次申请加盟，代理慕思经典品牌',
      '2、慕思经典与新渠道需分开提交流程',
      '3、如加盟的城市在“授权城市”选项没有找到对应的城市，请使用企业微信联系综合管理部乔瑞宁新增',
    ]"
  >
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.title"
      :key="key"
      delay-update
      class="formItem"
      :prop="`dealerDeclarationForm[${key}]`"
      :value="
        key === 'title'
          ? (form.dealerDeclarationForm.title = title)
          : form.dealerDeclarationForm[key]
      "
      :label="value"
      placeholder="归档后生成"
      disabled
      :required="false"
    />
    <!-- 招商单号 -->
    <RequiredFormItemInput
      v-model="form.zsOrderNumber"
      label="招商单号"
      class="formItem"
      prop="zsOrderNumber"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.optUser"
      :key="key"
      v-model="form.dealerDeclarationForm[key]"
      delay-update
      :placeholder="placeholder[key] || null"
      class="formItem"
      :prop="`dealerDeclarationForm[${key}]`"
      :label="value"
      disabled
    />
    <RequiredFormItemInput
      delay-update
      class="formItem"
      label="最终售达方编号"
      :value="form.finalLicenseNumber"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      delay-update
      class="formItem"
      label="OA账号"
      :value="form.dealerBoss.bossNumber"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      delay-update
      class="formItem"
      label="经销商平台账号"
      :value="form.dealerBoss.bossNumber"
      placeholder="归档后生成"
      disabled
    />
    <RequiredFormItemInput
      delay-update
      class="formItem"
      label="送达方编码"
      :value="form.finalSenderNumber"
      placeholder="归档后生成"
      disabled
    />
    <!-- <RequiredFormItemInput
        delay-update
        class="formItem"
        placeholder="归档后生成"
        :required="false"
        disabled
        v-model="form.dealerBusinessLicense4[key]"
        v-for="(value, key) in formLayout.dealerBusinessLicense4"
        :key="value"
        :label="value"
    />-->

    <HeaderTitle
      style="margin-top: 20px"
      title="区域经营规划"
    />

    <div>
      <RequiredFormItemSelect
        v-model="form.province"
        prop="province"
        class="formItem"
        :data-list="provinceList"
        label="省份"
        value-key="province"
        label-key="province"
        @change="
          () => {
            form.city = ''
            form.authorizedCityCode = ''
          }
        "
      />
      <RequiredFormItemSelect
        v-model="form.city"
        prop="city"
        class="formItem"
        clearable
        :disabled="!form.province"
        :get-data-list="getAuthCityList"
        :get-data-list-params="form.province"
        label="地级市"
        value-key="city"
        label-key="city"
        :placeholder="form.province ? null : '请选择省份'"
        @change="
          () => {
            form.area = ''
            form.authorizedCityCode = ''
          }
        "
      />
      <RequiredFormItemSelect
        ref="authorizedCityRef"
        v-model="form.authorizedCityCode"
        class="formItem"
        clearable
        :disabled="!form.city"
        prop="authorizedCityCode"
        :get-data-list="getAuthAreaList"
        :get-data-list-params="`${form.province},${form.city}`"
        label="授权城市确认"
        value-key="authorizedCityCode"
        :label-key="item => `${item.city}${item.area || ''}${item.town || ''}`"
        @changeSelectedItem="
          selectedItem => (authorizedSelectedItem = selectedItem)
        "
      />
      <RequiredFormItemInput
        delay-update
        :placeholder="placeholder['authorizedCityCode']||null"
        class="formItem"
        :value="form.marketLevelName ?? ''"
        prop="marketLevelName"
        label="市场级别判定"
        disabled
      />
      <RequiredModuleFormItemSelect
        v-model="form.joinTypeCode"
        class="formItem"
        prop="joinTypeCode"
        label="加盟类型"
        type="open_type"
        :filter-data-list="onFilterOpenTypeDataList"
        @onChangeItem="(item)=> form.joinTypeName = item.content"
      />

      <RequiredModuleFormItemSelect
        v-model="form.brandTypeCode"
        type="classic_new_channel"
        delay-update
        class="formItem"
        prop="brandTypeCode"
        label="品牌类型"
        @change="onChangeClassicNewChannel"
        @onChangeItem="(e)=> {form.brandTypeName = e?.content}"
      />

      <RequiredBrandFormItemSelect
        ref="dealeShopBrandRef"
        class="formItem"
        prop="applyBrandSeries"
        :value="form.applyBrandSeries"
        :value-translate="list => list.map(item => item.id)"
        label="授权品牌确认"
        :filter-data-list="(list)=> onFilterBrandDataList(list,form.brandTypeCode)"
        :disabled="form.brandTypeCode === ''"
        placeholder="请选择品牌类型"
        @changeSelectedItem="
          list =>
            (form.applyBrandSeries = (list || []).map(item => ({
              ...item,
              brandName: item.name
            })))
        "
      />
      <!-- 营销中心 -->
      <RequiredFormItemInput
        v-model="form.marketCenter"
        label="营销中心"
        class="formItem"
        prop="marketCenter"
        disabled
      />

    </div>

    <HeaderTitle
      style="margin-top: 20px"
      title="营业执照信息"
    />
    <RequiredFormItemInput
      delay-update
      :placeholder="placeholder['creditCode'] || null"
      class="formItem"
      prop="dealerBusinessLicense.creditCode"
      :value="form.dealerBusinessLicense.creditCode"
      label="统一社会信用代码"
      strong
      :validations="validations.creditCode"
      @blur="e => (form.dealerBusinessLicense.creditCode = e.target.value)"
    />
    <RequiredFormItemInput
      v-model="form.dealerBusinessLicense.businessLicenseName"
      delay-update
      :placeholder="placeholder['businessLicenseName'] || null"
      class="formItem"
      prop="dealerBusinessLicense.businessLicenseName"
      label="营业执照名称"
      :disabled="isLicenseDisabled"
    />
    <RequiredFormItemInput
      v-model="form.dealerBusinessLicense.businessLicenseShortName"
      delay-update
      :placeholder="placeholder['businessLicenseShortName'] || null"
      class="formItem"
      prop="dealerBusinessLicense.businessLicenseShortName"
      label="简称"
      :disabled="isLicenseDisabled"
    />

    <RequiredModuleFormItemSelect
      v-model="form.dealerBusinessLicense.businessLicenseType"
      class="formItem"
      prop="dealerBusinessLicense.businessLicenseType"
      label="营业执照类型"
      type="business_license_type"
      :disabled="isLicenseDisabled"
      label-tip="
一、营业执照类型为有限责任公司的，就通过一般纳税人查询官网确认纳税类型是否为一般纳税人
  一般纳税人查询官网：http://www.foochen.com/zty/ybnsr/yibannashuiren.html
  1、若属于一般纳税人，纳税类型选择一般纳税人，对应的发票类型为增值税专用发票；
  2、若不属于一般纳税人，纳税类型选择小规模纳税人，对应的发票类型为增值税电子发票；
二、营业执照类型为个体工商户的，则对应的纳税类型为定额纳税，对应的发票类型为增值税电子发票
"
      @change="onChangeSelectedBusinessLicenseType"
    />

    <RequiredFormItemInput
      v-model="form.dealerBusinessLicense.registeredCapital"
      delay-update
      :placeholder="placeholder['registeredCapital'] || null"
      class="formItem"
      prop="dealerBusinessLicense.registeredCapital"
      label="注册资本（万元)"
      type="number"
      :disabled="isLicenseDisabled"
    />

    <component
      :is="
        key === 'registerDate'
          ? 'RequiredFormItemDatePicker'
          : 'RequiredFormItemInput'
      "
      v-for="(value, key) in formLayout.dealerBusinessLicense"
      :key="key"
      v-model="form.dealerBusinessLicense[key]"
      delay-update
      :placeholder="placeholder[key] || null"
      class="formItem"
      :prop="`dealerBusinessLicense[${key}]`"
      :label="value"
      :validations="validations[key] || []"
      :disabled="isLicenseDisabled"
    />
    <div>
      <RequiredFormItemUploadFile
        v-model="form.businessLicenseFileList"
        prop="businessLicenseFileList"
        class="formItem"
        label="上传营业执照"
        limit-size="400"
      >
        <div slot="describe">
          <p style="color: red">先上传营业执照可智能识别出营业执照信息。</p>
          （营业执照照片/电子档）
        </div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
    </div>

    <HeaderTitle style="margin-top: 20px" title="开票信息" />
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.dealerBusinessLicense2"
      :key="value"
      v-model="form.dealerBusinessLicense[key]"
      delay-update
      class="formItem"
      :prop="`dealerBusinessLicense[${key}]`"
      :label="value"
      :disabled="isLicenseDisabled"
      :validations="validations[key] || []"
    />

    <RequiredFormItemSelect
      v-model="form.dealerBusinessLicense.payTaxesType"
      class="formItem"
      prop="dealerBusinessLicense.payTaxesType"
      label="纳税类型"
      :data-list="pay_taxes_type"
      label-key="content"
      value-key="code"
      disabled-key="disabled"
      @change="onChangePayTaxesType"
    />
    <RequiredModuleFormItemSelect
      v-model="form.dealerBusinessLicense.billType"
      class="formItem"
      prop="dealerBusinessLicense.billType"
      label="发票类型"
      module="public"
      type="invoice_type"
      disabled
    />
    <RequiredBankFormItemSelect
      v-model="form.dealerBusinessLicense.bank"
      :prop="`dealerBusinessLicense.bank`"
      class="formItem"
      label="开户行"
      delay-update
      :validations="validations['bank'] || []"
      :disabled="isLicenseDisabled"
      @changeSelectedItem="onChangeSelectedItemBank"
    />
    <RequiredFormItemInput
      v-model="form.dealerBusinessLicense.bankCode"
      :prop="`dealerBusinessLicense.bankCode`"
      class="formItem"
      label="开户行编码"
      :validations="validations['bankCode'] || []"
      readonly
      delay-update
    />
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.dealerBusinessLicense3"
      :key="key"
      v-model="form.dealerBusinessLicense[key]"
      delay-update
      :placeholder="placeholder[key] || null"
      class="formItem"
      :prop="`dealerBusinessLicense[${key}]`"
      :label="value"
      :validations="validations[key] || []"
      :disabled="isLicenseDisabled"
    />

    <div>
      <RequiredFormItemUploadFile
        v-model="form.taxpayerProveFileList"
        prop="taxpayerProveFileList"
        class="formItem"
        label="上传附件"
        style="width: inherit"
        limit-size="400"
      >
        <div slot="describe">
          （1：一般纳税人登记证 或2：税务登记事项通知书或
          3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片）
        </div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>

      <RequiredFormItemUploadFile
        v-model="form.bankFileList"
        prop="bankFileList"
        class="formItem"
        label="上传附件"
        style="width: inherit"
        limit-size="400"
      >
        <div slot="describe">（开户证明或者网银截图(能显示开户银行全称)）</div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
    </div>

    <HeaderTitle
      style="margin-top: 20px"
      title="经销商个人基础信息"
    />
    <RequiredFormItemInput
      v-model="form.dealerBoss.idCard"
      delay-update
      class="formItem"
      prop="dealerBoss.idCard"
      label="身份证"
      :validations="validations['idCard'] || []"
      :disabled="isLicenseDisabled"
    />
    <RequiredFormItemInput
      v-for="(value, key) in formLayout.userInfo2"
      :key="key"
      v-model="form.dealerBoss[key]"
      delay-update
      :placeholder="placeholder[key] || null"
      class="formItem"
      :prop="`dealerBoss[${key}]`"
      :label="value"
      :validations="validations[key] || []"
      :disabled="idCartDisabled"
    />
    <RequiredModuleFormItemSelect
      v-model="form.dealerBoss.education"
      class="formItem"
      prop="dealerBoss.education"
      label="学历"
      type="education"
      :disabled="isLicenseDisabled"
      :required="false"
    />
    <RequiredFormItemInput
      v-model="form.dealerBoss.age"
      delay-update
      :placeholder="placeholder['age'] || null"
      class="formItem"
      prop="dealerBoss.age"
      label="年龄"
      type="number"
      min="1"
      :validations="validations['age']"
      :disabled="isLicenseDisabled"
    />
    <RequiredModuleFormItemSelect
      v-model="form.dealerBoss.maritalStatus"
      prop="dealerBoss.maritalStatus"
      class="formItem"
      label="婚姻状况"
      type="marital_status"
      :disabled="isLicenseDisabled"
      :required="false"
    />
    <RequiredFormItemInput
      v-model="form.dealerBoss.nativePlace"
      delay-update
      :placeholder="placeholder['nativePlace'] || null"
      class="formItem"
      prop="dealerBoss.nativePlace"
      label="籍贯"
      :disabled="isLicenseDisabled"
    />
    <RequiredModuleFormItemSelect
      v-model="form.dealerBoss.sex"
      class="formItem"
      prop="dealerBoss.sex"
      label="性别"
      type="sex"
      :disabled="isLicenseDisabled"
    />

    <RequiredModuleFormItemSelect
      v-model="form.channelType"
      class="formItem"
      prop="channelType"
      label="经销商类型"
      type="dealer_type"
      network
      @onChangeItem="(selectedItem)=> {
        form.channelTypeS = selectedItem.content
      }"
    />

    <RequiredModuleFormItemSelect
      ref="secondaryChannelTypeRef"
      v-model="form.secondaryChannelType"
      prop="secondaryChannelType"
      class="formItem"
      type="dealer_sub_type"
      label="经销商类型二级分类"
      :required="false"
      @onChangeItem="(selectedItem)=> {
        form.secondaryChannelTypeS = selectedItem.content
      }"
      @change="
        () => {
          form.thirdChannelType = ''
          form.thirdChannelTypeS = ''
        }
      "
    />

    <RequiredModuleFormItemSelect
      ref="thirdChannelTypeRef"
      v-model="form.thirdChannelType"
      prop="thirdChannelType"
      class="formItem"
      type="dealer_thirdary_type"
      label="经销商类型三级分类"
      :parent-code="form.secondaryChannelType"
      empty-disabled
      dependent-parent-code
      :required="false"
      @onChangeItem="(selectedItem)=> {
        form.thirdChannelTypeS = selectedItem.content
      }"
    />
    <RequiredModuleFormItemSelect
      v-model="form.joinNature"
      class="formItem"
      prop="joinNature"
      label="经销商性质"
      type="join_nature"
    />

    <!-- <RequiredModuleFormItemSelect
      v-model="form.dealerBoss.nationCode"
      type="nation"
      delay-update
      :placeholder="placeholder['nationCode'] || null"
      class="formItem"
      prop="dealerBoss.nationCode"
      label="民族"
      module="public"
      :disabled="isLicenseDisabled"
      @onChangeItem="(item)=> form.dealerBoss.nationName = item.content"
    />
    <RequiredFormItemInput
      v-model="form.dealerBoss.eMail"
      delay-update
      :placeholder="placeholder['eMail'] || null"
      class="formItem"
      prop="dealerBoss.eMail"
      label="邮箱"
      :disabled="isLicenseDisabled"
    /> -->

    <HeaderTitle
      style="margin-top: 20px"
      title="其他信息"
    />

    <div>
      <RequiredModuleFormItemSelect
        v-model="form.agentTypeName"
        prop="agentTypeName"
        class="formItem"
        label="代理模式"
        value-key="content"
        type="agent_type"
        @onChangeItem="(item)=> form.agentTypeNameCode = item.code"
      />
      <component
        :is="
          value.moduleSelectType
            ? 'RequiredModuleFormItemSelect'
            : 'RequiredFormItemInput'
        "
        v-for="(value, key) in formLayout.otherInfo"
        :key="key"
        v-model="form[key]"
        delay-update
        class="formItem"
        :label="value.label"
        :prop="key"
        :type="value.moduleSelectType"
        :validations="validations[key] || []"
        :required="checkOtherInfoRequired(key, form.agentTypeName)"
      />
    </div>

    <HeaderTitle
      style="margin-top: 20px"
      title="品牌保证金收取"
    />
    <div style="margin-bottom: 10px">
      <CommonTable
        :default-data-list="form.dealerMarginRecordList"
        :columns="dealerMarginRecordListColumns"
        :table-layout-direction="isMobile ? 'horizontal' : 'vertical'"
        :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
        style="border: 0; border-radius: 0"
      />
    </div>
    <HeaderTitle style="margin-top: 20px" title="货物及快递收货信息" />
    <div style="margin-bottom: 10px">
      <component
        :is="
          value.moduleSelectType
            ? 'RequiredModuleFormItemSelect'
            : 'RequiredFormItemInput'
        "
        v-for="(value, key) in formLayout.warehouseAndDelivery"
        :key="key"
        v-model="form.warehouseAndDelivery[key]"
        delay-update
        class="formItem"
        :label="value.label"
        :prop="`warehouseAndDelivery[${key}]`"
        :type="value.moduleSelectType"
        :validations="validations[key] || []"
        @onChangeItem="(e)=> {
          form.warehouseAndDelivery[`${key}S`] = e.content
        }"
      />
    </div>

    <div>
      <RequiredFormItemUploadFile
        v-for="(value, key) in formLayout.uploadFileList"
        :key="key"
        v-model="form[key]"
        :prop="key"
        class="formItem"
        :label="value"
        limit-size="400"
        :required="checkUploadFileRequired(key, isClassicNew,form.agentTypeName)"
      >
        <!-- <div slot="describe">{{ value }}</div> -->
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
    </div>
    <RemarkItem
      v-model="form.dealerDeclarationForm.remark"
      style="margin-top: 40px"
      :file-list="form.otherFileList"
      @onUpload="
        file => {
          form.otherFileList = file.map(item => item.response)
        }
      "
    />
  </FlowForm>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'

import * as classicDealerJoinApi from '@/api/attractPlatform/classicDealerJoinApi'
import { getProvinceOrCityOrAreaList, requestGetSizeBarListByDeptMerge, requestGetCityLevel } from '@/api/common/'
import { getBusinessDataList } from '@/api/businessOos/index'
import { getBrandLeagueCoefficientInfo, ocrBizlicense } from '@/api/flow/index'

import { creditCodeReg, userIdReg } from '@/utils/useFormValidator'

import CommonTable from '@/components/TablePro/Table.vue'
import { render } from '@/components/TablePro/fn'

import UploadFile from '@/components/UploadFile/index'
import GoBack from '@/components/goBack'
import RequiredFormItem from '@/components/CustomFormItem/requiredFormItem.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequiredBrandFormItemSelect from '@/components/CustomFormItem/requiredBrandFormItemSelect.vue'
import RequiredBankFormItemSelect from '@/components/CustomFormItem/requiredBankFormItemSelect.vue'
import moduleData from '@/components/CustomFormItem/data/module.data.js'
import RemarkItem from './components/remarkItem'

import HeaderTitle from './components/headerTitle'
import FlowForm from './components/flowForm'

import { common } from '@/mixin/mixin'

const validations = {
  realName: ['name'],
  contactInfo: ['phone', 'check_boss_number'],
  idCard: ['user_id'],
  eMail: ['email'],
  creditCode: ['credit_code'],
  // taxpayerPhone: ['phone'],
  bankAccount: ['bank_account'],
  personLiableName: ['name'],
  personLiablePhone: ['phone'],
  renovationLiablePerson: ['name'],
  renovationPersonPhone: ['phone'],
  contactPerson: ['name'],
  contactPersonIdcard: ['user_id'],
  contactPersonPhone: ['phone'],
  warehouseUser: ['name'],
  warehousePhone: ['phone'],
  legalPersonContact: ['phone'],
  age: ['age'],
  // accountName: ['name'],
  account: ['bank_account'],
  shopTel: ['phone'],
  addresseeName: ['name'],
  addresseePhone: ['phone']
}

const originalForm = {
  // 经销商个人基础信息
  dealerDeclarationForm: {
    id: '', // 表单id，如果有则返回，如果没有则代表新建
    title: '', // 标题
    orderNumber: '', // 单号
    optJobNumber: '', // 提单人工号
    optUserId: '', // 提单人id
    optUserName: '', // 提单人工号
    optDeptId: '', // 提单部门id
    optDeptName: '', // 提单部门
    optTime: moment().format('YYYY-MM-DD hh:mm:ss'), // 提单日期
    // dealerType: '', // 0新经销商1老经销商
    // urgentLevel: '', // 0正常1紧急
    // urgentExplain: '',
    // statusExplain: '',
    remark: '' // 备注文本
  },
  // 经销商个人基础信息
  dealerBoss: {
    bossNumber: '', // 实控人账号
    realName: '', // 经销商（实际控股人）
    contactInfo: '', // 联系方式
    idCard: '', // 身份证
    tencentQq: '', // QQ电邮
    education: '', // 学历
    age: '', // 年龄
    maritalStatus: '', // 0未婚1已婚2离异
    nativePlace: '',
    sex: '', // 性别'0保密1男2女
    createTime: moment().format('YYYY-MM-DD hh:mm:ss'),
    nationCode: '', // 民族
    nationName: '', // 民族
    eMail: '' // 邮箱
  },
  // 营业执照信息
  dealerBusinessLicense: {
    businessLicenseName: '', // 营业执照名称
    businessLicenseType: '', // 营业执照类型
    creditCode: '', // 社会统一信用代码
    registeredCapital: '', // 注册资本
    legalPersonName: '', // 法人姓名
    legalPersonContact: '', // 法人联系方式
    registerDate: '', // 注册日期
    businessTerm: '', // 营业期限
    businessLicenseAddress: '', // 营业执照地址
    businessScope: '', // 经营范围
    registrationAuthority: '', // 登记机关
    // 开票信息
    taxpayerPhone: '', // 纳税人电话
    bank: '', // 开户行
    bankCode: '', // 开户行编号
    bankAccountName: '', // 开户名
    payTaxesType: '', // 纳税类型
    billType: '', // 专票/普票
    personLiableName: '', // 税务负责人姓名
    personLiablePhone: '', // 税务负责人电话
    oaAccount: '', // OA账号
    createTime: moment().format('YYYY-MM-DD hh:mm:ss'),
    eMail: '', // 接收电子发票邮箱
    bankAccount: '', // 银企直连账户
    licenseNumber: '', // 售达方
    certificatesRecAddress: '', // 函证接收地址

    reconciliationAccountS: '', // 统驭科目 名称
    reconciliationAccount: '', // 统驭科目
    tradingItemS: '', // 交易条款 名称
    tradingItem: '', // 交易条款
    paymentConditionS: '', // 付款条件 名称
    paymentCondition: '', // 付款条件
    businessLicenseShortName: '', //	营业执照简称
    salesDepartmentCode: '', // 销售部门编码
    salesDepartmentS: ''// 销售部门名称
  },
  // 品牌保证金
  dealerMarginRecordList: [
    {
      type: '2', // 保证金类型
      remittanceAmount: '', // 汇款金额（万）
      remittanceDate: '', // 汇款日期
      remittanceUser: '', // 付款人
      accountName: '', // 开户名
      account: '', // 账号
      bank: '', // 开户行
      financeConfirmStatus: '', // 0未到账1已到账
      paymentTime: '', // 入账时间
      fileList: [] // 文件列表
    }
  ],
  finalBusinessLicenseName: '', // 最终售达方名称
  finalLicenseNumber: '', // 最终售达方编号 OA账号
  finalBossAccount: '', // 实控人账号
  finalSenderNumber: '', // 最终送达方编号，多个送达方采用 "-" 进行分割
  warehouseAndDelivery: {
    warehouseUser: '', // 仓库收货人
    warehousePhone: '', // 联系方式
    warehouseAddress: '', // 仓库收货地址
    deliveryAddress: '', // 运输目的地
    deliveryType: '', // 运输方式
    deliveryTypeS: '', // 运输方式
    transportType: '', // 运输条件
    transportTypeS: '', // 运输条件
    paymentConditionCode: 'Z013', // 付款条件
    paymentConditionCodeS: '预付款' // 付款条件
  },

  // 区域经营规划
  province: '', // 省份
  city: '', // 地级市
  authorizedCityName: '', // 授权城市 名称
  authorizedCityCode: '', // 授权城市 编码
  marketLevelName: '', // 市场级别名称
  marketLevelCode: '', // 市场级别编号
  joinTypeName: '', // 加盟类型
  joinTypeCode: '', // 加盟类型编码
  applyBrandSeries: [], // 授权品牌
  brandTypeCode: '', // 经典新渠道标识
  brandTypeName: '', // 经典新渠道标识

  marketCenter: '', // 营销中心
  marketCenterCode: '', // 营销中心

  // 其他信息
  agentTypeName: '', // 代理模式
  agentTypeNameCode: '', // 代理模式
  shareholdingRatio: '', // 持股比例
  partnerNumber: '', // 合作人数
  companyPhone: '', // 公司电话
  companyFax: '', // 公司传真
  importantDocumentDeliveryAddres: '', // 重要文件快递地址
  addresseeName: '', // 收件人
  addresseePhone: '', // 收件人联系方式
  postCode: '', // 邮政编码

  // 附件
  businessLicenseFileList: [], // 营业执照照片/电子档
  taxpayerProveFileList: [], // （1：一般纳税人登记证 或2：税务登记事项通知书或 3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片）
  bankFileList: [], // 开户证明或者网银截图(能显示开户银行全称)
  classicJoinAssessFileList: [], // 慕思经典加盟申请人评估表
  businessPlanFileList: [], // 慕思经典经销商经营计划书
  giveUpCompetingList: [], // 慕思经典经销商放弃竞品经销权承诺书
  competingBusinessList: [], // 当地主要竞品经营概况及应对策略
  fairAndBrightFileList: [], // 慕思经典意向加盟人公平光明承诺书
  blankJoinFileList: [], // 慕思经典新商空白加盟承诺书
  guaranteeFileList: [], // 慕思经典经销商保证书
  promiseFileList: [], // 承诺书
  // intentionCustomPPTFileList: [], // 意向客户资料PPT
  joinFormFileList: [], // 加盟申请表
  bossJointDeclarationFileList: [], // 实际控制人联合声明
  classsicChannelJoinFormFileList: [], // 慕思经典装企渠道经营权加盟申请表
  intentionFileList: [], // 装企意向经销商经营计划表
  openShopAndPerformanceFileList: [], // 2023年经销商装企渠道开店及业绩承诺书
  honestyAndIntegrityFileList: [], // 慕思装企经销商廉洁诚信承诺书
  lastThreeMonthBankFlowFileList: [], // 近3个月银行流水（余额）
  // personalBankDepositsFileList: [], // 个人银行正式存款证明
  idCardFileList: [], // 身份证（正面、反面）
  partnershipAgreementFileList: [], // 合股协议书（合伙经营需提供）
  partnerIdCardFileList: [], // 合股人身份证（正面和反面，合伙经营需提供）
  otherFileList: [], // 其他

  channelType: '', // 经销商类型分类
  channelTypeS: '', // 经销商类型分类名称
  secondaryChannelType: '', // 经销商类型二级分类名称
  secondaryChannelTypeS: '', // 经销商类型二级分类名称
  thirdChannelType: '', // 经销商类型三级分类名称
  thirdChannelTypeS: '', // 经销商类型三级分类名称
  joinNature: '' // 经销商性质
}

export default {
  components: {
    UploadFile,
    HeaderTitle,
    GoBack,
    RequiredFormItem,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredFormItemDatePicker,
    RequiredFormItemUploadFile,
    RequiredModuleFormItemSelect,
    RequiredBrandFormItemSelect,
    RequiredBankFormItemSelect,
    FlowForm,
    RemarkItem,
    CommonTable
  },
  mixins: [common],
  data() {
    const currDayTime = moment().startOf('day').valueOf()
    const that = this
    return {
      form: JSON.parse(JSON.stringify(originalForm)),
      shopTypeSelectedItem: null,
      authorizedSelectedItem: null,
      provinceList: [],

      formLayout: {
        title: {
          title: '标题',
          orderNumber: '单号'
        },

        optUser: {
          optJobNumber: '提单人工号',
          optUserName: '提单人',
          optDeptName: '提单部门',
          optTime: '提单日期'
        },
        // userInfo1: {
        //   idCard: "身份证"
        // },
        userInfo2: {
          realName: '实际控股人',
          contactInfo: '实际控股人联系方式'
        },
        dealerBusinessLicense: {
          legalPersonName: '法人',
          legalPersonContact: '法人联系方式',
          registerDate: '注册日期',
          businessTerm: '营业期限',
          businessLicenseAddress: '营业执照地址（住所）',
          businessScope: '经营范围',
          registrationAuthority: '登记机关',
          certificatesRecAddress: '函证接收地址'
        },
        dealerBusinessLicense2: {
          businessLicenseName: '账户名称',
          creditCode: '纳税人识别号',
          businessLicenseAddress: '营业执照地址',
          taxpayerPhone: '纳税人电话'
        },
        dealerBusinessLicense3: {
          bankAccount: '银行账号',
          personLiableName: '税务负责人',
          personLiablePhone: '税务负责人电话',
          eMail: '接受电子发票邮箱'
        },
        warehouseAndDelivery: {
          warehouseUser: { label: '仓库收货人' },
          warehousePhone: { label: '联系方式' },
          warehouseAddress: { label: '仓库收货地址' },
          deliveryAddress: { label: '运输目的地' },
          deliveryType: {
            label: '运输方式',
            moduleSelectType: 'delivery_type'
          },
          transportType: {
            label: '运输条件',
            moduleSelectType: 'transport_type'
          },
          paymentConditionCode: {
            label: '付款条件',
            moduleSelectType: 'settlement_type'
          }
        },
        otherInfo: {
          shareholdingRatio: { label: '持股比例' },
          partnerNumber: { label: '合作人数' },
          companyPhone: { label: '公司电话' },
          companyFax: { label: '公司传真' },
          importantDocumentDeliveryAddres: { label: '重要文件快递地址' },
          addresseeName: { label: '收件人' },
          addresseePhone: { label: '收件人联系方式' },
          postCode: { label: '邮政编码' }
        },
        uploadFileList: {
          classicJoinAssessFileList: '《慕思经典加盟申请人评估表》',
          businessPlanFileList: '《慕思经典经销商经营计划书》',
          giveUpCompetingList: '《慕思经典经销商放弃竞品经销权承诺书》',
          competingBusinessList: '《当地主要竞品经营概况及应对策略》',
          fairAndBrightFileList: '《慕思经典意向加盟人公平光明承诺书》',
          blankJoinFileList: '《慕思经典新商空白加盟承诺书》',
          guaranteeFileList: '《慕思经典经销商保证书》',
          promiseFileList: '《承诺书》',
          // intentionCustomPPTFileList: '意向客户资料PPT',
          joinFormFileList: '加盟申请表',
          bossJointDeclarationFileList: '实际控制人联合声明',

          lastThreeMonthBankFlowFileList: '近3个月银行流水（余额）',
          // personalBankDepositsFileList: '个人银行正式存款证明',
          idCardFileList: '身份证（正面、反面）',
          partnershipAgreementFileList: '合股协议书（合伙经营需提供）',
          partnerIdCardFileList: '合股人身份证（正面和反面，合伙经营需提供）',

          classsicChannelJoinFormFileList: '《慕思经典装企渠道经营权加盟申请表》',
          intentionFileList: '《装企意向经销商经营计划表》',
          openShopAndPerformanceFileList: '《2023年经销商装企渠道开店及业绩承诺书》',
          honestyAndIntegrityFileList: '《慕思装企经销商廉洁诚信承诺书》'
          // otherFileList: '其他'
        }
      },
      validations,
      licenseDetail: null,
      idCartDetail: null,
      placeholder: {
        registrationAuthority: '请填写营业执照右下方登记机关红章名称',
        bank: '详细至营业点，**分行/分理处',
        certificatesRecAddress: '实控人接收慕思财务下发函证资料地址',
        nativePlace: '广东东莞',
        capitalSource: '自有资金/政策贷款/其他',
        oaAccount: '归档后生成',
        advertisingInvestment: '年度广告投入具体单位新增（万）单位',
        businessTotalTime: '已从事时间指年份',
        shopFloor: '填写示例:1层',
        shopFloorNumber: '填写示例:1号'
      },
      // 最小时间
      pickerOptions: {
        disabledDate(time) {
          return currDayTime > time.getTime()
        }
      },

      dealerMarginRecordListColumns: [
        {
          title: '类型',
          name: 'type',
          width: 200,
          render: render((h, context) => {
            return (
              <RequiredModuleFormItemSelect
                v-model={context.data.type}
                class='formItem'
                prop={`dealerMarginRecordList.${context.index}.type`}
                show-label={false}
                label='类型'
                type='payment_type_code'
                disabled
              />
            )
          })
        },
        {
          title: '金额（万）',
          name: 'remittanceAmount',
          width: 180,
          render: render((h, context) => {
            return (
              <RequiredFormItemInput
                prop={`dealerMarginRecordList.${context.index}.remittanceAmount`}
                class='formItem'
                rules={this.maxNumberRule(100)}
                min={0}
                label={context.config.title}
                delay-update
                type='number'
                show-label={false}
                v-model={context.data.remittanceAmount}
                disabled
              />
            )
          })
        },
        {
          title: '汇款日期',
          name: 'remittanceDate',
          render: render((h, context) => {
            return (
              <RequiredFormItemDatePicker
                required={!!context.data.remittanceAmount}
                v-model={context.data.remittanceDate}
                prop={`dealerMarginRecordList.${context.index}.remittanceDate`}
                label='汇款日期'
                disabled={!context.data.remittanceAmount}
                show-label={false}
              />
            )
          })
        },
        {
          title: '汇款账号',
          name: 'account',
          minWidth: 200,
          render: render((h, context) => {
            return (
              <div>
                <RequiredFormItemInput
                  v-model={context.data.accountName}
                  required={!!context.data.remittanceAmount}
                  prop={`dealerMarginRecordList.${context.index}.accountName`}
                  show-label={false}
                  label='开户名'
                  validations={validations['accountName'] || []}
                  disabled={!context.data.remittanceAmount}
                />
                <el-divider />
                <RequiredFormItemInput
                  v-model={context.data.account}
                  required={!!context.data.remittanceAmount}
                  prop={`dealerMarginRecordList.${context.index}.account`}
                  show-label={false}
                  label='账号'
                  validations={validations['account'] || []}
                  disabled={!context.data.remittanceAmount}
                />
                <el-divider />
                <RequiredBankFormItemSelect
                  v-model={context.data.bank}
                  required={!!context.data.remittanceAmount}
                  prop={`dealerMarginRecordList.${context.index}.bank`}
                  show-label={false}
                  label='开户行'
                  validations={validations['bank'] || []}
                  disabled={!context.data.remittanceAmount}
                />
              </div>
            )
          })
        },
        {
          title: '款条',
          name: 'fileList',
          minWidth: 200,
          render: render((h, context) => {
            return (
              <RequiredFormItemUploadFile
                v-model={context.data.fileList}
                required={!!context.data.remittanceAmount}
                class='formItem'
                limit-size='400'
                prop={`dealerMarginRecordList.${context.index}.fileList`}
                auto-upload
                disabled={!context.data.remittanceAmount}
              >
                <div slot='tip'>单个文件不能超过400M</div>
              </RequiredFormItemUploadFile>
            )
          })
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapGetters('commonConfig', ['bigBrandMap', 'seriesMap']),
    ...mapState({
      isOA: state => state.dealershipTransfer.isOA,
      isMobile: state => state.app.device === 'mobile' || state.app.isWxworkMoblie,
      allBrandData: state => state.commonConfig.allBrandData
    }),
    // 包含慕思经典
    isClassic({ form: { applyBrandSeries }, bigBrandMap }) {
      const classicId = bigBrandMap.get('慕思经典')?.id
      console.log({ classicId, applyBrandSeries })
      return applyBrandSeries.some(item => item.parentId === classicId)
    },
    // 包含海外
    isOverseas({ form: { applyBrandSeries }, bigBrandMap }) {
      const overseaCode = bigBrandMap.get('慕思海外')?.code
      return applyBrandSeries.some(item => item.achievementCode === overseaCode)
    },
    // 包含V6
    isV6({ form: { applyBrandSeries }}) {
      return applyBrandSeries.some(item => item.name.includes('V6'))
    },
    // 包含慕思经典-新渠道
    isClassicNew({ form: { applyBrandSeries }, seriesMap }) {
      const classicId = seriesMap.get('慕思经典-新渠道')?.id
      return applyBrandSeries.some(item => item.id === classicId)
    },
    // 标题名称获取
    title({ form, authorizedSelectedItem }) {
      let authCity = ''
      const realName = form.dealerBoss.realName || ''
      if (authorizedSelectedItem) {
        const { province, city, area } = authorizedSelectedItem
        authCity = `${province || ''}${city || ''}${area || ''}`
      }
      const brand = form.applyBrandSeries.map(item => item.name).join('，')
      const list = []
      if (authCity) {
        list.push(authCity)
      }
      if (realName) {
        list.push(realName)
      }
      if (brand) {
        list.push(`授权品牌：${brand}`)
      }
      let str = '慕思经典经销商加盟流程申请表'
      if (list.length) {
        str += `（${list.join('，')}）`
      }
      return str
    },

    // 送达方= 省市区-法人-授权品牌的大品牌,逗号分割,1,2,3
    senderName({
      form: {
        dealerBusinessLicense: { legalPersonName },
        applyBrandSeries
      },
      authorizedSelectedItem
    }) {
      let senderName = ''
      if (!this.$refs.dealeShopBrandRef) return

      const parentList = this.$refs.dealeShopBrandRef.parentList

      if (authorizedSelectedItem && legalPersonName) {
        const { city, area } = authorizedSelectedItem
        const authCity = `${city || ''}${area || ''}`

        const brand = applyBrandSeries
          .reduce((previousValue, currentValue) => {
            const parentItem = parentList.find(
              parentItem => currentValue.parentId === parentItem.id
            )
            if (parentItem) {
              if (
                !previousValue.find(
                  parentItem => currentValue.parentId === parentItem.id
                )
              ) {
                previousValue.push(parentItem)
              }
            }
            return previousValue
          }, [])
          .map(item => item.name)
          .join('，')

        if (brand) {
          senderName = `${authCity}-${legalPersonName}-授权品牌：${brand}`
        }
      }

      return senderName
    },

    isLicenseDisabled({
      licenseDetail,
      form: {
        dealerBusinessLicense: { creditCode }
      }
    }) {
      return !!licenseDetail || !creditCodeReg.test(creditCode)
    },
    idCartDisabled({
      isLicenseDisabled,
      idCartDetail,
      form: {
        dealerBoss: { idCard }
      }
    }) {
      return !!idCartDetail || !!isLicenseDisabled || !userIdReg.test(idCard)
    },
    pay_taxes_type({ form }) {
      const payTaxesType = moduleData.pay_taxes_type
      switch (form.dealerBusinessLicense.businessLicenseType) {
        case '0':
          return payTaxesType.map(item => {
            return {
              ...item,
              disabled: item.code !== '1'
            }
          })
        case '1':
          return payTaxesType.map(item => {
            return {
              ...item,
              disabled: item.code === '1'
            }
          })
        default:
          return payTaxesType
      }
    },
    marketLevelCode_brandCode({
      form: {
        marketLevelCode,
        applyBrandSeries
      }
    }) {
      return {
        marketLevelCode,
        brandCode: applyBrandSeries.map(item => item.code)
      }
    }

  },
  watch: {
    marketLevelCode_brandCode: {
      immediate: true,
      async handler(value) {
        if (!value.marketLevelCode || !value.brandCode.length) {
          this.form.dealerMarginRecordList[0].remittanceAmount = ''
          return
        }
        const res = await getBrandLeagueCoefficientInfo(value)
        this.form.dealerMarginRecordList[0].remittanceAmount = res.data.brandDeposit
      }
    },
    authorizedSelectedItem({ authorizedCityCode, city, area } = {}) {
      this.form.authorizedCityCode = authorizedCityCode
      this.form.authorizedCityName = `${city}${area || ''}`
      if (authorizedCityCode) {
        requestGetCityLevel({ authorizedCityCode }).then(res => {
          this.form.marketLevelCode = res.data?.[0]?.marketLevelCode ?? ''
          this.form.marketLevelName = res.data?.[0]?.marketLevelName ?? ''
        })
      } else {
        this.form.marketLevelCode = ''
        this.form.marketLevelName = ''
      }
      this.getGetSizeBarListByDept()
    },
    'form.applyBrandSeries'() {
      this.getGetSizeBarListByDept()
    },
    'form.dealerBusinessLicense.creditCode': {
      immediate: true,
      handler(creditCode) {
        if (this.isOA) return

        if (!creditCode) return (this.licenseDetail = null)
        getBusinessDataList({
          creditCode
        })
          .then(res => {
            if (res.data.length) {
              const licenseDetail = res.data[0]
              this.licenseDetail = licenseDetail
            } else {
              this.licenseDetail = null
            }
          })
          .catch(() => {
            this.licenseDetail = null
          })
      }
    },
    'form.dealerBoss.idCard': {
      immediate: true,
      handler(idCard) {
        if (this.isOA) return
        if (this.licenseDetail) {
          return
        }
        if (!idCard) return (this.idCartDetail = null)
        classicDealerJoinApi
          .getBossByIdCard({
            idCard
          })
          .then(res => {
            if (res.data) {
              this.idCartDetail = res.data
            } else {
              this.idCartDetail = null
            }
          })
          .catch(() => {
            this.idCartDetail = null
          })
      }
    },
    'form.dealerBusinessLicense.legalPersonContact': {
      immediate: true,
      handler(value) {
        this.form.dealerBusinessLicense.taxpayerPhone = value
      }
    },
    'form.dealerBusinessLicense.businessLicenseName': {
      immediate: true,
      handler(value) {
        this.form.dealerBusinessLicense.bankAccountName = value
      }
    },
    licenseDetail(licenseDetail) {
      if (licenseDetail) {
        for (const key in this.form.dealerBusinessLicense) {
          this.form.dealerBusinessLicense[key] = licenseDetail[key]
        }
        for (const key in this.form.dealerBoss) {
          if (licenseDetail[key] !== undefined) {
            this.form.dealerBoss[key] = licenseDetail[key]
          }
        }
      } else {
        this.form.dealerBusinessLicense = {
          ...originalForm.dealerBusinessLicense,
          creditCode: this.form.dealerBusinessLicense.creditCode
        }
        this.form.dealerBoss = {
          ...originalForm.dealerBoss
        }
      }
    },
    idCartDetail(idCartDetail) {
      if (idCartDetail) {
        for (const key in this.form.dealerBoss) {
          if (idCartDetail[key] !== undefined) {
            this.form.dealerBoss[key] = idCartDetail[key]
          }
        }
      } else {
        this.form.dealerBoss = {
          ...originalForm.dealerBoss,
          idCard: this.form.dealerBoss.idCard
        }
      }
    },
    'form.businessLicenseFileList'(arr) {
      // OCR识别营业执照
      if (arr.length && arr[0]['url']) {
        const params = { imgUrl: arr[0]['url'], appId: 'wx27748066d2817941' }
        ocrBizlicense(params).then((res = {}) => {
          if (Number(res.data.errcode) === 0) {
            const data = res.data
            this.form.dealerBusinessLicense.creditCode = data.reg_num // 统一社会信用代码
            this.form.dealerBusinessLicense.businessLicenseName =
              data.enterprise_name // 营业执照名称
            this.form.dealerBusinessLicense.businessLicenseType =
              data.type_of_enterprise.includes('个体工商户')
                ? '0'
                : '1' // 营业执类型
            // this.form.dealerBusinessLicense.registeredCapital =data.registered_capital//注册资本
            this.form.dealerBusinessLicense.legalPersonName =
              data.legal_representative // 法人姓名

            const registerDate = moment(
              data.registered_date.replace(/日|\s/g, '').replace(/年|月/g, '-')
            ).format('YYYY-MM-DD HH:mm:ss')
            this.form.dealerBusinessLicense.registerDate =
              registerDate.toLocaleLowerCase() === 'invalid date'
                ? ''
                : registerDate // 注册日期
            this.form.dealerBusinessLicense.businessTerm = data.valid_period // 营业期限
            this.form.dealerBusinessLicense.businessLicenseAddress =
              data.address // 营业执照地址
            this.form.dealerBusinessLicense.businessScope = data.business_scope // 经营范围
          } else {
            if (Number(res.data.errcode) === 101003) return
            this.$message.warning(
              '您上传的营业执照未能识别成功，请检查营业执照图片是否清晰或手工填写信息。'
            )
          }
        })
      }
    }
  },
  methods: {
    onChangeClassicNewChannel(brandTypeCode) {
      if (brandTypeCode === '') {
        this.form.applyBrandSeries = []
      } else if ([0, 2].includes(Number(brandTypeCode))) {
        this.form.applyBrandSeries = [this.seriesMap.get('慕思经典-新渠道')].map(item => ({
          ...item,
          brandName: item.name
        }))
      } else {
        // 初始化全部经典品牌
        this.form.applyBrandSeries = this.allBrandData.filter(item => {
          return item.parentId === this.bigBrandMap.get('慕思经典')?.id
        })
          .filter((item) => {
            return item.id !== this.seriesMap.get('慕思经典-新渠道')?.id
          })
          .map(item => ({
            ...item,
            brandName: item.name
          }))
      }
    },
    checkUploadFileRequired(key, isClassicNew, agentTypeName) {
      if (key === 'giveUpCompetingList') {
        return false
      }

      if ([
        'lastThreeMonthBankFlowFileList',
        'personalBankDepositsFileList',
        'idCardFileList',
        'partnershipAgreementFileList',
        'partnerIdCardFileList'
      ].includes(key)) {
        if (['partnershipAgreementFileList', 'partnerIdCardFileList'].includes(key)) {
          if (isClassicNew || agentTypeName !== '合作代理') {
            return false
          }
        }
        return !isClassicNew
      }

      if (['classsicChannelJoinFormFileList', 'intentionFileList', 'openShopAndPerformanceFileList', 'honestyAndIntegrityFileList'].includes(key)) {
        return isClassicNew
      } else {
        return !isClassicNew
      }
    },
    checkOtherInfoRequired(key, agentTypeName) {
      if (agentTypeName === '独资代理') {
        return !['shareholdingRatio', 'partnerNumber', 'companyPhone', 'companyFax', 'postCode'].includes(key)
      }
      if (['companyPhone', 'companyFax', 'postCode'].includes(key)) {
        return false
      }
      return true
    },
    onFilterBrandDataList(list, brandTypeCode) {
      // 筛选慕思经典系列
      return list.filter(item => {
        return item.parentId === this.bigBrandMap.get('慕思经典').id
      }).filter(item => {
        if (Number(brandTypeCode) === 0) {
          return this.seriesMap.get('慕思经典-新渠道').id === item.id
        } else {
          return this.seriesMap.get('慕思经典-新渠道').id !== item.id
        }
      })
    },
    onFilterOpenTypeDataList(list) {
      return list.filter(item => {
        return !item.content.includes('换商')
      })
    },
    maxNumberRule(maxNumber = 100) {
      return [
        {
          validator(rule, value, callback) {
            if (Number(value) >= maxNumber) {
              return callback(`请输入小于${maxNumber}的数字`)
            }
            callback()
          },
          trigger: 'blur'
        }
      ]
    },
    getGetSizeBarListByDept() {
      if (!this.authorizedSelectedItem || this.form.applyBrandSeries.length === 0) {
        this.form.marketCenter = ''
        this.form.marketCenterCode = ''
        this.form.brandRegionCode = ''
      } else {
        return requestGetSizeBarListByDeptMerge({
          provinceCode: this.authorizedSelectedItem.provinceCode,
          cityCode: this.authorizedSelectedItem.cityCode,
          areaCode: this.authorizedSelectedItem.areaCode,
          townCode: this.authorizedSelectedItem.townCode,
          brandIds: this.form.applyBrandSeries.map(item => item.id).join(','),
          deptId: ''
        }).then(res => {
          this.form.marketCenter = res.data.smallName
          this.form.marketCenterCode = res.data.smallCode
          this.form.brandRegionCode = res.data.brandRegionCode
        })
      }
    },
    onChangeSelectedItemBank(bankItem) {
      this.form.dealerBusinessLicense.bankCode = bankItem ? bankItem.CODE : ''
      this.form.dealerBusinessLicense.bankCountryCode = bankItem ? bankItem.AREACODE : ''
    },
    // 修改营业执照类型,自动带出纳税类型
    onChangeSelectedBusinessLicenseType(e) {
      switch (String(e)) {
        case '0':
          this.form.dealerBusinessLicense.payTaxesType = '1'
          break
        case '1':
          if (this.form.dealerBusinessLicense.payTaxesType === '1') {
            this.form.dealerBusinessLicense.payTaxesType = ''
          }
          break
        default:
          this.form.dealerBusinessLicense.payTaxesType = ''
      }
      this.onChangePayTaxesType(this.form.dealerBusinessLicense.payTaxesType)
    },

    // 修改纳税类型，自动带出发票类型
    onChangePayTaxesType(e) {
      switch (String(e)) {
        case '0':
          // 一般纳税人
          this.form.dealerBusinessLicense.billType = '1'
          break
        case '2':
          // 小规模纳税人
          this.form.dealerBusinessLicense.billType = '2'
          break
        case '1':
          // 定额纳税人
          this.form.dealerBusinessLicense.billType = '2'
          // this.form.dealerBusinessLicense.billType = '4'
          break
        default:
          this.form.dealerBusinessLicense.billType = ''
      }
    },

    getAuthCityList({ getDataListParams: province }) {
      if (!province) return []
      return getProvinceOrCityOrAreaList({
        type: 'city',
        province
      }).then(({ data }) => data)
    },
    async onSubmit(type) {
      const time = moment().format('YYYY-MM-DD hh:mm:ss')
      this.form.dealerBoss.createTime = time
      this.form.dealerBusinessLicense.createTime = time
      this.form.dealerDeclarationForm.optTime = time

      // 如果选择慕思经典任意系列，需在品牌额外添加慕思助眠、芯睡眠
      // const findTargetId = this.bigBrandMap.get('慕思经典')?.id
      // const existTargetCodeInApplyBrandSeries = this.form.applyBrandSeries
      //   .filter(item => {
      //     return ![this.seriesMap.get('慕思经典-新渠道').code].includes(
      //       item.code
      //     )
      //   })
      //   .some(item => {
      //     return item.parentId === findTargetId
      //   })
      // const existTargetCodeInDealeShopBrandList =
      //   this.form.applyBrandSeries.some(item => {
      //     return item.parentId === findTargetId
      //   })

      // if (existTargetCodeInApplyBrandSeries) {
      //   this.form.applyBrandSeries = lodash.unionBy(
      //     this.form.applyBrandSeries,
      //     [this.seriesMap.get('助眠系列'), this.seriesMap.get('芯睡眠')],
      //     'code'
      //   )
      // }

      // if (existTargetCodeInDealeShopBrandList) {
      //   this.form.applyBrandSeries = lodash.unionBy(
      //     this.form.applyBrandSeries,
      //     [this.seriesMap.get('助眠系列'), this.seriesMap.get('芯睡眠')].map(
      //       ({ id, ...item }) => {
      //         return {
      //           brandId: id,
      //           updateReason: '1',
      //           sampleSets: '0',
      //           brandArea: '0',
      //           ...item
      //         }
      //       }
      //     ),
      //     'code'
      //   )
      // }

      const { data } = await classicDealerJoinApi.saveDraft(this.form)
      const formId = data.id
      if (type !== 'submit') {
        return formId
      }
      const formData = new FormData()
      formData.append('formId', formId)
      return classicDealerJoinApi.submit(formData)
    },
    async getProvince() {
      return (this.provinceList = await getProvinceOrCityOrAreaList({
        type: 'province'
      }).then(res => res.data))
    },
    async getDetail(type, cachedFormData) {
      let initFormData = cachedFormData
      // 编辑状态
      if (type === 'detail') {
        const { data } = await classicDealerJoinApi.detail({
          formId: this.$route.query.id
        })
        initFormData = data
      }

      if (initFormData) {
        for (const key in this.form) {
          if (initFormData[key] !== undefined) {
            this.form[key] = initFormData[key]
          }
        }
      }
      if (this.userInfo && !this.isOA) {
        const {
          optUserId,
          optJobNumber,
          optUserName,
          optDeptId,
          optDeptName,
          ...rest
        } = this.form.dealerDeclarationForm || {}
        this.form.dealerDeclarationForm = {
          ...rest,
          optUserId: optUserId || this.userInfo.accountId,
          optJobNumber: optJobNumber || this.userInfo.code,
          optUserName: optUserName || this.userInfo.realName,
          optDeptId: optDeptId || this.userInfo.deptId,
          optDeptName: optDeptName || this.userInfo.deptName
        }
      }
      await this.getProvince()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.tip {
  color: $danger-dark-color;
}
</style>
